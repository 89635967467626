const init = function(){
	cssScrollSnapPolyfill()
}
init();

var horizontalScrollBreakpoint = 1100;




// ************************************************************
/* 
	## cookieBanner
*/
function cookieBanner() {
	$('.js-cookie-close').click(function() {
		$(this).closest('.cookie-banner').addClass("cookie-banner--closed");
	});
}
// ************************************************************

// ************************************************************
/* 
	## modal
*/
function modal() {
	$('.js-modal-toggle').click(function() {
		if ($('.modal').hasClass("modal--active")) {
			$('.modal').removeClass('modal--active');
			$('html').removeClass('modal-overflow');
		} else {
			$('.modal').addClass('modal--active');
			$('html').addClass('modal-overflow');
		}
	});
}
// ************************************************************


// ************************************************************
/* 
	## equalHeights
	Sets all child elements (level 1) of the target class to an equal height (the tallest)
*/
function equalHeights(targetClass) {
    $(targetClass).each(function() {
		var $tallest = 0;
		var $heightItem = $(this).find('>*');
		
		$heightItem.each(function() {
            if ($(this).height() > $tallest) {
                $tallest = $(this).height();
            }
        })
       $heightItem.height($tallest);
	});
}
// ************************************************************

// ************************************************************
/* 
	## inViewport
	Adds & removes an active class to the target element when it enters the viewport
*/
function inViewport(targetClass){
	$(targetClass).each(function(){
		var triggerHeight = $(window).innerHeight() / 2;
		var divPos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var activeClass = targetClass.replace('.','') + "--in-viewport";

		if (divPos <= topOfWindow + triggerHeight ) {
			$(this).addClass(activeClass);
		} else {
			$(this).removeClass(activeClass)
		}
	});
}
// ************************************************************

// ************************************************************
/* 
	## inViewport target classes 
	The elements that are effected by the inViewport function
*/
function inViewportTargets(){
	inViewport(".js-scroll-panels-start");
	inViewport(".js-scroll-panels-end");
	inViewport(".scroll-panels__item");
	inViewport(".alternating__item");
	inViewport(".intro");
	inViewport(".graph-embed__embed");
	inViewport(".scroll-panels-x");
	inViewport(".fixed-column__item--fixed");
	inViewport(".fixed-column__content");
	inViewport(".js-count");
	inViewport(".stat-block__stat");
}
// ************************************************************

// ************************************************************
/* 
	## verticalScrollInitialize
	Vertical Scroll - Assign & remove class to html element at start
	& end of panel block - this controlls the scroll snap
*/
function verticalScrollInitialize() {
	$(".scroll-panels").each(function(){
		var scrollStartClass = $(this).find(".js-scroll-panels-start");
		var scrollEndClass = $(this).find(".js-scroll-panels-end");

		if (scrollStartClass.hasClass("js-scroll-panels-start--in-viewport")) {
			$("html").addClass("scroll-panels-container");
		} else {
			$("html").removeClass("scroll-panels-container");
		}
		if (scrollEndClass.hasClass("js-scroll-panels-end--in-viewport")) {
			$("html").removeClass("scroll-panels-container");
		}

		return false;
	});
}
// ************************************************************


// ************************************************************
/* 
	## verticalScrollVideoPosition
	- adds a negative margin & height to the direct next video element to pull it up behind the vertical scrolling panels
	- the conditional is there as a placeholder while the content is added - will likely need revisiting
*/
function verticalScrollVideoPosition() {
	$(".scroll-panels").each(function(){
		if ($(this).next().hasClass("scroll-panels-video")) {
			var elementHeight = $(this).height();
			var panelVideoWrapper = $(this).next();
			var windowHeight = $(window).height();

			if (elementHeight > windowHeight) {
				panelVideoWrapper.css({
					'margin-top' : -elementHeight,
					'height' : elementHeight
				})
			} else {
				panelVideoWrapper.css({
					'margin-top' : -elementHeight,
					'height' : elementHeight
				})
			}
		}
	});
}
// ************************************************************


// ************************************************************
/* 
	## verticalScrollNav
	Vertical scroll Assign & remove active class to panel nav
*/
function verticalScrollNav() {
	$(".scroll-panels__item").each(function(){
		if ($(this).hasClass("scroll-panels__item--in-viewport")) {
			var panelID = $(this).attr('id');

			$(".js-scroll-panel-nav a").removeClass("active");
			$("a[href='#"+ panelID +"']").addClass("active")
		} else {
			$("a[href='#"+ panelID +"']").removeClass("active")
		}
	});
}
// ************************************************************

// ************************************************************
/* 
	## horizontalScrollInitialize
	Horizontal scroll - this initializes the plugin
	// the containerHeight sets the scroll speed - 100vh per slide seems about right. eg: 3 slides = 300vh
*/
function horizontalScrollInitialize() {
	$('.scroll-panels-x__wrapper--1').horizontalScroll({
		containerHeight: "300vh"
	});

	$('.scroll-panels-x__wrapper--2').horizontalScroll({
		containerHeight: "200vh"
	});

	// This is currently borked having two on the page at the same time - from trying to debug, it looks like the 'strip-holder' element is animating on both at the same time
	// need to look at the unminified code & see if targeting the specific 'strip-holder' elements is possible
}


// ************************************************************
/* 
	## horizontalScrollMargins
	Horizontal scroll - The plugin adds some extra space top & bottom so this is here to fix the vertical rhythm
	Make sure this is run after the equalHeights function to get the true element height
*/
function horizontalScrollMargins() {
	$(".scroll-panels-x").each(function(){
		var elementHeight = $(this).find(".scroll-panels-x__item").first().outerHeight();
		var marginValue = elementHeight / 2;
		$(this).css({
			'margin-top' : - marginValue,
			'margin-bottom' : - marginValue
		})
	});
}
// ************************************************************
// ************************************************************
/* 
	## horizontalScrollBackground
	Horizontal scroll - This appends a new element within the container generated by the horizontalScroll plugin & assignes it a background from the first slide items image.
*/
function horizontalScrollBackground() {
	$(".scroll-panels-x").each(function(){
		var firstPanelImage = $(this).find(".scroll-panels-x__item:first-of-type .scroll-panels-x__image");
		var firstPanelImageSrc = firstPanelImage.attr('data-image');
		$(this).find(".sticky-holder").append("<div class='scroll-panels-x__background' style='background-image: url(" + firstPanelImageSrc + ");'></div>");
	});
}
// ************************************************************

// ************************************************************
/* 
	## horizontalScrollStickyBackgrounds
	The plugin got most of the way there in terms of basic horizontal scrolljacking. The following code is for:
	- Adding a class to the scrolling item as it enters the viewport
	- Grabbing the data-image src from each item & setting it as the items background image
	- Grabbing the data-image src from each item & setting it as the bg image on the appended background element. This is how the sticky image is achieved
	- Theres some commented out lines utilising the panelPositionLeft value, this would have been a simpler solution but was too jittery. Have left in incase it can be fixed later
*/
function horizontalScrollStickyBackgrounds() {
	$(".scroll-panels-x__item").each(function(){
		if ($(this).closest(".scroll-panels-x").hasClass("scroll-panels-x--in-viewport")) {
			var panelPositionLeft = $(this).offset().left;

			var panelImage = $(this).find(".scroll-panels-x__image");
			var panelImageSrc = panelImage.attr('data-image');

			panelImage.css("background-image", "url('"+ panelImageSrc +"')")
			
			if (panelPositionLeft <= 0 ) {
				$(this).addClass("scroll-panels-x__item--in-viewport");
				// this isn't quite smooth enough - could clone img to fixed background? set bg on sticky-holder
				// panelImage.css("left", - panelPositionLeft)
				$(this).closest(".sticky-holder").find(".scroll-panels-x__background").css("background-image", "url('" + panelImageSrc + "')");
			} else {
				$(this).removeClass("scroll-panels-x__item--in-viewport")
				// panelImage.css("left", "0")
			}
		}
	});
}
// ************************************************************


// ************************************************************
/* 
	## Number Ticker
*/

function doTicker(el) {
	countTo = el.attr("data-count");
	$({
		countNum: el.text(),
	}).animate({
		countNum: countTo,
	},
	{
		duration: 2000,
		easing: "swing",
		step: function () {
			el.text(Math.floor(this.countNum));
		},
		complete: function () {
			el.text(this.countNum);
		},
	});
}

function doTickerReset(el) {
	el.text(0);
}

$(".js-count").each(function() {
	var counted = 0;
	var $this = $(this);
	$(window).scroll(function () {
		if ($this.hasClass("js-count--in-viewport") && counted == 0) {
			doTicker($this);
			counted = 1;
		}

		if (!$this.hasClass("js-count--in-viewport") && counted == 1) {
			doTickerReset($this);
			counted = 0;
		}
	});
});

// ************************************************************



$(document).ready(function() {
	inViewportTargets();
	verticalScrollInitialize();

	if ($(window).width() >= horizontalScrollBreakpoint) {
		equalHeights(".scroll-panels-x__wrapper");
		horizontalScrollInitialize();
		horizontalScrollBackground();
		horizontalScrollMargins();
	}

	verticalScrollVideoPosition();
	modal();
	cookieBanner();
});


$(window).scroll(function() {
	inViewportTargets();

	verticalScrollInitialize();
	verticalScrollNav();

	if ($(window).width() >= horizontalScrollBreakpoint) {
		horizontalScrollStickyBackgrounds();
	}
});

// $(window).resize(function() {});
